<template>
  <div class="page-container">
    <template v-if="groupList.length">
      <div>
        <donate-group-item
          v-for="(item, idx) in groupList"
          :key="idx"
          :group="item.group"
          :name="item.name"
          :avatar="item.avatar"
          :amount="item.amount"
          :member="item.member"
          :time="item.time"
          :message="item.message"
          :leader="item.leader"
          :relation="item.relation"
        />
      </div>
    </template>
    <template v-else>
      <van-empty description="暂无数据" />
    </template>
  </div>
</template>

<script>
import DonateGroupItem from "@/components/DonateGroupItem";
import { getMyGroupList } from "@/api/mine";

export default {
  components: { DonateGroupItem },
  data() {
    return {
      groupList: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getMyGroupList().then((response) => {
        this.groupList = response;
      });
    },
  },
};
</script>

<style>
</style>
